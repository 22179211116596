import * as React from 'react';
import { useState } from 'react';
import {
  CardDataFields,
  AccordionDataFields,
  TableDataFields
} from '../data-mapping';

export const tsConnectionContext = React.createContext({});

// Field mappings.
export const FieldsMappingContext = React.createContext({
  card: [],
  accordion: [],
  table: [],
});

export const FieldsMappingContextProvider = ({children}) => {
  const [cardMapping, setCardMapping] = useState(CardDataFields);
  const [accordionMapping, setAccordionMapping] = useState(AccordionDataFields);
  const [tableMapping, setTableMapping] = useState(TableDataFields);

  const store = {
    card: [cardMapping, setCardMapping],
    accordion: [accordionMapping, setAccordionMapping],
    table: [tableMapping, setTableMapping],
  };
  return <FieldsMappingContext.Provider value={store}>
    {children}
  </FieldsMappingContext.Provider>
};

export const ViewOptionsContext = React.createContext({
  card: []
});

export const QueryContext = React.createContext({
  filterBy: [],
});

export const ViewOptionsContextProvider = ({children}) => {
  const [cardViewOptions, setCardViewOptions] = useState({
    hasImagePadding: false,
    isClickableCard: false,
    truncatePretitle: false,
  });

  const store = {
    card: [cardViewOptions, setCardViewOptions],
  };
  return <ViewOptionsContext.Provider value={store}>
    {children}
  </ViewOptionsContext.Provider>
}

export const QueryContextProvider = ({children}) => {
  const [filterBy, setFilterBy] = useState({
    filterBy: [''],
  });

  const store = {
    filterBy: [filterBy, setFilterBy],
  };

  return <QueryContext.Provider value={store}>
    {children}
  </QueryContext.Provider>
}
