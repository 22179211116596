import * as React from 'react';

/**
 * Offcanvas component.
 * https://getbootstrap.com/docs/5.3/components/offcanvas/
 *
 * @param {Object} props
 * @returns {JSX.Element}
 */
export default function (props) {
  const {
    title,
    children,
  } = props;
  return (
    <div className='offcanvas offcanvas-start' tabIndex='-1' id='offcanvas' aria-labelledby='offcanvasLabel'>
      <div className='offcanvas__content'>
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title h--smaller' id='offcanvasLabel'>{title}</h5>
          <button type='button' className='btn-close' data-bs-dismiss='offcanvas' aria-label={I18n.t('faceted_search.filters.close')}></button>
        </div>
        <div className='offcanvas-body'>
          {children}
        </div>
        <div className='offcanvas-footer'>
          <div className='offcanvas-footer__actions'>
            <button
              type='button'
              className='btn btn-cta btn-cta--light'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvas'
              aria-controls='offcanvas'
              onClick={() => {
                document.querySelector('.faceted-search-clear-filters button')?.click()
              }}
            >
              {I18n.t('faceted_search.filters.clear')}
            </button>
            <button
              type='button'
              className='btn btn-cta'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvas'
              aria-controls='offcanvas'
            >
              {I18n.t('faceted_search.filters.apply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
