import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { InstantSearch } from 'react-instantsearch';
import store, { addFilters, setColumns, setIndexes, setSearchClientConfig } from './grouped-table/store';
import { buildAdapter, requestErrorHandler } from './grouped-table/helper';
import ComponentPreview from './grouped-table/partials/ComponentPreview';
import Group from './grouped-table/partials/Group';
import FiltersBlock from './grouped-table/partials/FiltersBlock';

const futureFlags = () => {
  return {
    preserveSharedStateOnUnmount: true,
  };
};

const normalizeFilters = (filters = [], columns) => {
  return filters.map(({attribute}) => {
    return {
      attribute,
      widget: 'checkboxes',
      fieldName: attribute,
      label: columns[attribute],
    };
  });
};

const GroupedTableComponent = (props) => {
  const {configUrl, tableStyle} = props;
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [defaultIndex, setDefaultIndex] = useState('');

  const columns = useSelector(state => state.app.columns);
  const indexes = useSelector(state => state.app.indexes);
  const typesenseClientConfig = useSelector(state => state.app.searchClientConfig);
  const indexIDs = Object.keys(indexes);

  useEffect(() => {
    fetch(configUrl)
      .then(response => response.json())
      .then(response => {
        const {
          columns,
          indexes,
          defaultIndex,
          typesenseClientConfig,
          filters,
        } = response;

        dispatch(setColumns(columns));
        dispatch(setIndexes(indexes));
        dispatch(setSearchClientConfig(typesenseClientConfig));
        dispatch(addFilters(normalizeFilters(filters || [], columns)));
        setDefaultIndex(defaultIndex);
      }).catch(requestErrorHandler)
  }, [configUrl]);

  const adapter = useMemo(() => buildAdapter(indexIDs, typesenseClientConfig), [indexIDs]);

  if (!defaultIndex || !indexIDs.length) {
    return <ComponentPreview/>;
  }

  return (
    <div className='nv-grouped-table' ref={containerRef}>
      <InstantSearch searchClient={adapter.searchClient} indexName={defaultIndex} future={futureFlags()}>
        <FiltersBlock containerRef={containerRef}/>
        {indexIDs.map((indexID) => {
          const key = `group:${indexID}`;
          return <Group key={key} indexName={indexID} columns={columns} indexes={indexes} tableStyle={tableStyle}/>;
        })}
      </InstantSearch>
    </div>
  );
};

/**
 *
 * @param props
 * @returns {JSX.Element}
 */
export default function (props) {
  const {config_url: configUrl, table_style: tableStyle} = props;

  if (!configUrl) {
    return <ComponentPreview/>;
  }

  return (
    <Provider store={store}>
      <GroupedTableComponent configUrl={configUrl} tableStyle={tableStyle}/>
    </Provider>
  );
}
