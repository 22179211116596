import { showModalCommandName } from './editor';
import { appendToContent, decodeHTML } from '../utils/dom';

export default function loadCommands(editor, options = {}) {
  const {modalTitle, modalButtonLabel} = options;

  editor.Commands.add(showModalCommandName, {
    run: function (editor, sender, opts) {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const {target} = this;
      target && this.showCodeModal();
    },
    /**
     * Stops command execution.
     */
    stop() {
      editor.Modal.close();
    },
    /**
     * Displays modal window.
     */
    showCodeModal() {
      const {editor, options} = this;
      const title = options.title || modalTitle;
      const code = this.target.getEl().querySelector('textarea');
      const content = this.modalContent(code);

      editor.Modal
        .open({title, content})
        .getModel()
        .once('change:open', () => editor.stopCommand(this.id));
      this.getCodeViewer().focus();

      let html = decodeHTML(code.innerHTML.trim());

      this.getCodeViewer().editor.setValue(html);
    },
    /**
     * Builds modal content.
     *
     * @returns {HTMLDivElement}
     */
    modalContent(code) {
      const {editor} = this;
      const content = document.createElement('div');
      content.className = `${editor.getConfig('stylePrefix')}custom-code`;
      const codeViewer = this.getCodeViewer();
      content.appendChild(codeViewer.getElement());
      appendToContent(content, this.getContentActions());
      return content;
    },
    /**
     * Builds actions button.
     *
     * @returns {HTMLButtonElement}
     */
    getContentActions() {
      const {editor} = this;
      const btn = document.createElement('button');
      const pfx = editor.getConfig('stylePrefix');

      btn.innerHTML = modalButtonLabel;
      btn.style.marginTop = '0.5rem';
      btn.classList.add(`${pfx}btn-prim`);
      btn.classList.add(`${pfx}btn-import__code`);
      btn.onclick = () => this.handleSave();

      return btn;
    },
    /**
     * Return the code viewer instance.
     */
    getCodeViewer() {
      const {editor} = this;

      if (this.codeViewer) {
        return this.codeViewer;
      }

      this.codeViewer = editor.CodeManager.createViewer({
        codeName: 'htmlmixed',
        theme: 'hopscotch',
        readOnly: 0,
        styleActiveLine: true,
        lineWrapping: true,
      });

      return this.codeViewer;
    },
    /**
     * Handles the main save task.
     */
    handleSave() {
      const {target} = this;
      const modalValue = this.getCodeViewer().editor.getValue();
      target.getChildAt(0).components(`<pre><textarea>${modalValue}</textarea></pre>`);
      this.stop();
    },
  })
}
