import * as React from "react";
import { useEffect, useRef } from "react";
import { FacetedSearchRefinementList } from "./filters/FacetedSearchRefinementList";

export function useClickOutside(ref, handler) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event.target);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
}

export const DropdownFilter = (props) => {
  const {label, filter} = props;

  const ref = useRef(null);

  const handleClickOutside = (target) => {
    if (ref.current) {
      if (target.classList.contains('filters__reset')) {
        return;
      }

      const dropdown = ref.current.parentElement.querySelector('.filter__wrapper');
      dropdown.classList.add('d-none');
    }
  };

  useClickOutside(ref, handleClickOutside);
  const handleClick = (e) => {
    const dropdown = e.target.parentElement.querySelector('.filter__wrapper');
    dropdown.classList.toggle('d-none');
  }

  return (
    <div className='filter filter--dropdown'>
      <button className='p--medium filter__label' onClick={handleClick}>{label}</button>
      <div className='filter__wrapper d-none' ref={ref}>
        <div className='filter__content'>
          {[filter].map((filter, key) => {
            const widgets = {
              [filter.widget]: FacetedSearchRefinementList,
            }
            const Widget = widgets[filter.widget] || <div></div>;

            return <Widget
              key={key}
              showAll={true}
              header={filter.label || filter.fieldName}
              attributeName={filter.fieldName}
              attribute={filter.fieldName}
            />;
          })}
        </div>
      </div>
    </div>
  );
};
