import * as React from 'react';
import { useClearRefinements } from 'react-instantsearch';
import classnames from 'classnames';

export const ClearFilters = (props) => {
  const {canRefine, refine} = useClearRefinements(props);

  const css = {
    root: classnames({
      'faceted-search-clear-filters': true,
      'faceted-search-clear-filters--empty': canRefine === false,
    }),
  };

  return (
    <div className={css.root}>
      <button className='p--medium' disabled={!canRefine} onClick={refine}>
        {I18n.t('faceted_search.filters.clear_all')}
      </button>
    </div>
  );
};
