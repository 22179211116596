import * as React from 'react';
import { useRef } from 'react';
import { Configure, Index, SortBy } from 'react-instantsearch';
import HitsWidget from './HitsWidget';
import Pagination from '../../faceted-search/react/Pagination';

const HITS_PER_PAGE = 250;

/**
 * Group component.
 *
 * @param {Object} props
 * @param {String} props.indexName
 * @param {Object} props.columns
 * @param {Array} props.indexes
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function (props) {
  const {
    indexName,
    columns,
    indexes,
    tableStyle,
  } = props;

  const sortByRef = useRef(null);
  const index = indexes[indexName];
  const {
    label = '',
    fields = {},
    heading: headingText = '',
    footer: footerText = '',
  } = index;

  const sortByItems = [];

  Object
    .keys(fields)
    .filter(f => fields[f].sortable)
    .forEach(item => {
      sortByItems.push({label: `${columns[item]} (ASC)`, value: `${indexName}/sort/${item}:asc`});
      sortByItems.push({label: `${columns[item]} (DESC)`, value: `${indexName}/sort/${item}:desc`});
    });

  return (
    <div className='group mb-5'>
      <Index indexName={indexName}>
        <Configure hitsPerPage={HITS_PER_PAGE}/>
        <h2 className='h--smaller mb-3'>{label}</h2>
        <div ref={sortByRef} className='d-none'>
          <SortBy items={sortByItems}/>
        </div>
        {headingText && <p className='group__heading-text'>{headingText}</p>}
        <HitsWidget columns={columns} index={index} sortByItems={sortByItems} tableStyle={tableStyle}/>
        {footerText && <p className='group__footer-text p--small mb-0'>{footerText}</p>}
        <Pagination/>
      </Index>
    </div>
  );
}
