import * as React from 'react';
import classnames from 'classnames';
import { FieldsMappingContextProvider } from './Context';
import { Configure, HitsPerPage } from 'react-instantsearch';
import { ClearFilters } from './ClearFilters';
import { Pills } from './Pills';
import Pagination from './Pagination';
import CurrentPageStats from './CurrentPageStats';
import { getEnabled, sortByWeight } from './helper';
import { DropdownFilter } from './DropdownFilter';
import SortByWidget from './SortByWidget';

const CompactModeFilter = (props) => {
  const {filters, disabledFilters, label} = props;

  const activeFilters = getEnabled(filters, disabledFilters)
    .filter(f => f.compactMode === true);

  if (!activeFilters || !activeFilters.length) {
    return null;
  }

  activeFilters.sort(sortByWeight);

  const firstFilter = activeFilters.slice(0, 1)[0];

  return (
    <div className='filters'>
      <p className='filters__label p--medium fw-bold mb-0'>Filter by:</p>
      <DropdownFilter label={label} filter={firstFilter}/>
    </div>
  );
};

export const FacetedSearchLayoutCompact = (props) => {
  const {
    sortByOptions,
    filterBy,
    title,
    filters,
    disabledFilters,
    quickFiltersRef,
    css,
    itemsPerPage,
    SearchResults,
    viewMode,
    fieldsMapping,
    compactModeDropdownLabel,
    compactModeCtaText,
    compactModeCtaUrl,
    showFacetsCount = false
  } = props;

  const localCss = {
    root: classnames({
      'container': true,
      'my-3': true,
      'faceted-filters': true,
      'faceted-filters--compact': true,
      'faceted-filters--with-counter': showFacetsCount,
    })
  };

  return <>
    {filterBy && filterBy.length && <Configure filters={filterBy}/>}
    <div className='d-none'>
      <SortByWidget items={sortByOptions}/>
    </div>
    <div className={localCss.root}>
      <div className='row'>
        {title && <div className='col-lg-12 col-md-12 col-sm-12'>
          <h2 className={css.heading}>{title}</h2>
        </div>}
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='compact-filter-container'>
            <div className='compact-filter-container__filter'>
              <div className={css.stats}>
                <CompactModeFilter
                  filters={filters}
                  disabledFilters={disabledFilters}
                  label={compactModeDropdownLabel}
                />
                <HitsPerPage
                  items={[{value: itemsPerPage, label: `${itemsPerPage} items per page`, default: true}]}
                />
              </div>
            </div>
            <div className='compact-filter-container__link'>
              <div className='col-lg-6 col-md-12 col-sm-12 view-all-link'>
                <div className='d-inline-flex'>
                  <a href={compactModeCtaUrl} className='link-cta text-transform-unset text-nowrap'>{compactModeCtaText}</a>
                </div>
              </div>
            </div>
            <div className='compact-filter-container__selected'>
              <div className='faceted-search-pills-container'>
                <ClearFilters/>
                <Pills filters={filters} container={quickFiltersRef}/>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className={css.root}>
            <FieldsMappingContextProvider>
              <SearchResults viewMode={viewMode} fieldsMapping={fieldsMapping}/>
            </FieldsMappingContextProvider>
          </div>
          <div className='d-flex justify-content-between align-items-center faceted-search__footer footer--compact'>
            <div className='footer__stats'>
              <CurrentPageStats/>
            </div>
            <div className='footer__pagination'>
              <Pagination/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};
