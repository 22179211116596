import * as React from 'react';
import { FacetedSearchBox } from '../../filters/FacetedSearchBox';
import { FilterButton } from '../../helper';
import { DropdownFilter } from '../../DropdownFilter';

/**
 * Horizontal layout filters block.
 *
 * @param {Object} props
 * @param {Array} props.filters
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const FiltersBlock = (props) => {
  const {filters} = props;

  return (
    <div className='d-flex justify-content-between mb-0 filters-block'>
      <div className='filters d-flex align-items-center'>
        {filters.map((filter) => {
          const key = `${filter.fieldName}:${filter.widget}`;
          return <DropdownFilter
            key={key}
            filter={filter}
            label={filter.label || filter.fieldName}
          />
        })}
      </div>
      <div className='text-search-panel mb-0'>
        <div className='faceted-filters-form'>
          <FacetedSearchBox/>
        </div>
      </div>
      <FilterButton/>
    </div>
  )
}
