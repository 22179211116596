import * as React from 'react';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Hits, InstantSearch } from 'react-instantsearch';
import { history } from 'instantsearch.js/es/lib/routers';
import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';
import {
  FieldsMappingContext,
  QueryContext,
  ViewOptionsContext,
} from './Context';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { layouts, mappingTypes } from '../data-mapping';
import { CardSearchResult } from './search-results/CardSearchResult';
import { AccordionSearchResult } from './search-results/AccordionSearchResult';
import { FacetedSearchLayoutStandard } from './FacetedSearchLayoutStandard';
import { FacetedSearchLayoutCompact } from './FacetedSearchLayoutCompact';
import { ScrollTo } from './ScrollTo';
import classnames from 'classnames';
import { getEnabled, sortByWeight } from './helper';
import { FacetedSearchLayoutHorizontal } from "./FacetedSearchLayoutHorizontal";
import TableSearchResult from "./search-results/TableSearchResult";

/**
 * Builds search client adapter.
 *
 * @param {String} protocol
 * @param {String} host
 * @param {String} port
 * @param {String} apiKey
 * @param {Object} collection
 * @param {String} collection.name
 * @param {String} queryBy
 * @param {String} filterBy
 * @returns {TypesenseInstantSearchAdapter}
 */
function buildSearchClientAdapter(protocol, host, port, apiKey, collection, queryBy, filterBy) {
  const {name} = collection;
  // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  const cacheTime = 2 * 60;
  const config = {
    server: {
      apiKey: apiKey,
      nodes: [
        {
          host: host,
          port: port,
          path: '',
          protocol: protocol,
        },
      ],
      cacheSearchResultsForSeconds: cacheTime,
    },
    collectionSpecificSearchParameters: {},
  };

  config.collectionSpecificSearchParameters[name] = {
    query_by: 'title'
  };

  if (filterBy) {
    config.additionalSearchParameters = {
      filter_by: filterBy,
    }
  }

  if (queryBy && queryBy.trim().length > 0) {
    config.collectionSpecificSearchParameters[name]['query_by'] = queryBy;
  }

  return new TypesenseInstantSearchAdapter(config);
}

/**
 * Search results factory.
 *
 * @param {Object} props
 * @param {string} props.viewMode
 * @param {Object} props.fieldsMapping
 * @param {Object} props.fieldsMapping.card
 * @param {Object} props.fieldsMapping.accordion
 * @param {Object} props.fieldsMapping.table
 * @returns {JSX.Element}
 * @constructor
 */
const SearchResults = (props) => {
  const {viewMode, fieldsMapping} = props;
  const {card, accordion, table} = useContext(FieldsMappingContext);
  const [, setCardData] = card;
  const [, setAccordionData] = accordion;
  const [, setTableData] = table;

  useEffect(() => {
    setCardData(fieldsMapping.card);
    setAccordionData(fieldsMapping.accordion);
    setTableData(fieldsMapping.table);
  }, []);

  const viewModeComponents = {
    [mappingTypes.card]: <Hits hitComponent={CardSearchResult} />,
    [mappingTypes.accordion]: <Hits hitComponent={AccordionSearchResult} />,
    [mappingTypes.table]: <TableSearchResult />,
  };

  return viewModeComponents[viewMode] || <>View mode is not supported.</>;
};

const buildSortOptions = (sortBy, customSortBy, customSortByLabel, collectionName) => {
  const items = [];

  if (!sortBy) {
    return items;
  }

  const activeSortByFilters = getEnabled(sortBy);
  activeSortByFilters.sort(sortByWeight);

  let customSortByItem = null;
  const existingSortingKeys = [];
  if (customSortBy !== undefined && customSortBy.toString().trim().length > 0) {
    // E.g.: title:desc,created:desc
    const sortingValue = `${collectionName}/sort/${customSortBy}`;
    if (!customSortByLabel) {
      customSortByLabel = '';
    }
    customSortByItem = {
      value: sortingValue,
      label: customSortByLabel.toString().trim().length > 0 ? customSortByLabel : 'Default',
    }
    // Store sorting keys to avoid duplications.
    existingSortingKeys.push(sortingValue);
  }

  if (!activeSortByFilters.length && !customSortByItem) {
    return items;
  }

  if (customSortByItem) {
    items.push(customSortByItem);
  }

  for (const f of activeSortByFilters) {
    if (f.enabled && f.label) {
      const sortingValue = `${collectionName}/sort/${f.fieldName}:asc`;
      if (!existingSortingKeys.includes(sortingValue)) {
        items.push({value: sortingValue, label: f.label})
        existingSortingKeys.push(sortingValue);
      }
    }

    if (f.sortingDesc && f.labelSortingDesc) {
      const sortingValue = `${collectionName}/sort/${f.fieldName}:desc`;
      if (!existingSortingKeys.includes(sortingValue)) {
        items.push({value: sortingValue, label: f.labelSortingDesc})
        existingSortingKeys.push(sortingValue);
      }
    }
  }

  return items;
};

/**
 * Faceted search main component.
 *
 * @param {Object} props
 * @param {Object} props.collection
 * @param {string} props.collection.name
 * @param {Array}  props.collection.filters
 * @param {Object} props.collection.field_mappings
 * @param {Object} props.collection.field_mappings.card
 * @param {Object} props.collection.field_mappings.accordion
 * @param {string} props.viewMode
 * @param {string} props.title
 * @param {string} props.host
 * @param {string} props.protocol
 * @param {string} props.apiKey
 * @returns {JSX.Element}
 * @constructor
 */
export const FacetedSearchLayout = (props) => {
  const {collection, viewMode, viewOptions, filterBy = '', layout, hideCategoryLabel, cardsLayout} = props;
  const {protocol, host, port, apiKey, queryBy} = props;
  const {filters, field_mappings, sort_by: sortBy} = collection;
  const {card: cardViewOptionsCtx} = useContext(ViewOptionsContext);
  const {filterBy: filterByCtx} = useContext(QueryContext);
  const quickFiltersRef = useRef();

  // Styling.
  const css = {
    root: classnames({
      'faceted-filters-results': true,
      'faceted-filters-results--cards': viewMode === mappingTypes.card,
      'faceted-search-results--layout-3-col': cardsLayout && cardsLayout === 3,
      'faceted-search-results--layout-4-col': cardsLayout && cardsLayout === 4,
      'accordion': viewMode === mappingTypes.accordion,
    }),
    heading: classnames({
      'faceted-search-heading': true,
      'fsf-heading--cards': viewMode === mappingTypes.card,
      'fsf-heading--accordion': viewMode === mappingTypes.accordion,
      'h--medium': layout === layouts.compact,
    }),
    pills: classnames({
      'faceted-search-pills': true,
      'faceted-search-pills--no-labels': hideCategoryLabel === true,
      'faceted-search-stats-wrapper--cards': viewMode === mappingTypes.card,
      'faceted-search-pills--accordion': viewMode === mappingTypes.accordion,
    }),
    stats: classnames({
      'faceted-search-stats-wrapper': true,
      'faceted-search-stats-wrapper--cards': viewMode === mappingTypes.card,
    })
  };

  const adapter = useMemo(() => {
    return buildSearchClientAdapter(protocol, host, port, apiKey, collection, queryBy, filterBy);
  }, [protocol, host, port, apiKey, collection, queryBy, filterBy, layout, sortBy]);

  useEffect(() => {
    // Set initial view options for card.
    const [, setCardViewOptions] = cardViewOptionsCtx;
    setCardViewOptions(viewOptions);

    // Set filter by context.
    const [, setFilterBy] = filterByCtx;
    setFilterBy(filterBy);
  }, [viewOptions]);

  if (!adapter.searchClient) {
    return null;
  }

  const sortByOptions = buildSortOptions(sortBy, props.customSortBy, props.customSortByLabel, collection.name);

  const layoutComponents = {
    [layouts.compact]: FacetedSearchLayoutCompact,
    [layouts.horizontal]: FacetedSearchLayoutHorizontal,
    default: FacetedSearchLayoutStandard,
  };

  const LayoutComponent = layoutComponents[layout] || layoutComponents.default;

  const layoutComponent = (
    <LayoutComponent
      css={css}
      SearchResults={SearchResults}
      filters={filters}
      fieldsMapping={field_mappings}
      quickFiltersRef={quickFiltersRef}
      sortBy={sortBy}
      sortByOptions={sortByOptions}
      {...props}
    />
  );

  const futureFlags = {
    preserveSharedStateOnUnmount: true
  };

  const routing = {
    router: history({
      cleanUrlOnDispose: false,
    }),
    stateMapping: {
      routeToState(routeState) {
        const routeToStateCallback = singleIndex(collection.name).routeToState;
        if (routeState && Object.keys(routeState).length > 0) {
          return routeToStateCallback(routeState);
        }

        if (sortByOptions.length) {
          routeState['sortBy'] = sortByOptions[0].value;
        }

        return routeToStateCallback(routeState);
      },
      stateToRoute(uiState) {
        return singleIndex(collection.name).stateToRoute(uiState);
      }
    }
  };

  return (
    <InstantSearch
      indexName={collection.name}
      searchClient={adapter.searchClient}
      cleanUrlOnDispose={false}
      future={futureFlags}
      routing={routing}
    >
      <ScrollTo>{layoutComponent}</ScrollTo>
    </InstantSearch>
  );
}
