import * as React from 'react';
import { useCurrentRefinements } from 'react-instantsearch';
import { cleanLabel } from './helper';
import classnames from 'classnames';

/**
 * Pill component.
 *
 * @param {Object} item
 * @param {Object} filters
 * @param {CallableFunction} refine
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Pill = ({item, filters, refine}) => {
  const {attribute, refinements} = item;

  return <>
    {refinements.length > 0 && refinements.map((refinement, key) => {
      const filter = filters[attribute];

      if (!filter || !(filter?.label || filter.fieldName)) {
        return null;
      }

      return (
        <div className='ais-CurrentRefinements-item' key={key}>
          <div className="ais-CurrentRefinements-category">
              <span className="ais-CurrentRefinements-categoryLabel">
                <span className="ais-CurrentRefinements-label">{cleanLabel(filter.label || filter.fieldName)}: </span>
                {cleanLabel(refinement.value)}
              </span>
            <button
              type="button"
              className="ais-CurrentRefinements-delete"
              onClick={e => refine(refinement)}>
              ✕
            </button>
          </div>
        </div>
      );
    })}
  </>;
};

/**
 *
 * @param {Object} props
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const Pills = (props) => {
  const {container, filters} = props;
  const {items, refine} = useCurrentRefinements(props)

  const css = {
    root: classnames({
      'ais-CurrentRefinements': true,
      'ais-CurrentRefinements--empty': items.length === 0,
    })
  }

  if (container.current) {
    const cssEmptyClassName = 'faceted-search-pills--empty';
    if (items.length === 0) {
      container.current.classList.add(cssEmptyClassName);
    } else {
      container.current.classList.remove(cssEmptyClassName);
    }
  }

  return (
    <div className={css.root}>
      <div className="ais-CurrentRefinements-list">
        {items.map((item, key) => <Pill key={item.attribute} item={item} filters={filters} refine={refine}/>)}
      </div>
    </div>
  )
};
