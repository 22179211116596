import * as React from 'react';
import { usePagination } from 'react-instantsearch';
import prevPageIcon from './icons/angle-left.svg';
import nextPageIcon from './icons/angle-right.svg';
import firstPageIcon from './icons/angles-left.svg';
import lastPageIcon from './icons/angles-right.svg';
import downIcon from './icons/angle-down.svg';
import classnames from 'classnames';

/**
 * Pagination button.
 *
 * @param {Object} props
 * @param {String} props.icon
 * @param {CallableFunction} props.onClickHandler
 * @param {String} props.label
 * @param {Boolean} props.isDisabled
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PaginationButton = (props) => {
  const {icon, onClickHandler, label, isDisabled} = props;

  return (
    <button className='pagination__button' disabled={isDisabled} onClick={onClickHandler}>
      <img src={icon} alt={label}/>
    </button>
  );
}

/**
 * Pagination dropdown.
 *
 * @param {Object} props
 * @param {Array} props.pages
 * @param {CallableFunction} props.refine
 * @param {String} props.currentRefinementLabel
 * @param {Number} props.currentRefinement
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PaginationDropdown = (props) => {
  const {
    pages,
    refine,
    currentRefinementLabel,
    currentRefinement,
  } = props;

  return (
    <div className='pagination__dropdown'>
      <div className='pagination__dropdown-placeholder'>
        <div className='dropdown-placeholder__current-page'>{currentRefinementLabel}</div>
        <div className='dropdown-placeholder__icon'>
          <img src={downIcon} alt={`Page: ${currentRefinementLabel}`}/>
        </div>
      </div>
      <select value={currentRefinement} onChange={(e) => {
        const {target} = e;

        refine(parseInt(target.value));
      }}>
        {pages.map((page) => {
          const label = page + 1;
          return (
            <option key={`page:${page}`} value={page}>{label}</option>
          );
        })}
      </select>
    </div>
  );
}

/**
 * Pagination component.
 *
 * @param {Object} props
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Pagination = (props) => {
  // https://www.algolia.com/doc/api-reference/widgets/pagination/react/#widget-param-padding
  const maxPaddingItems = 1000;
  const options = {...props, padding: maxPaddingItems}
  const {
    pages,
    currentRefinement,
    nbPages,
    isFirstPage,
    isLastPage,
    refine,
  } = usePagination(options);
  const currentRefinementLabel = `${currentRefinement + 1}`;
  const firstPageIdx = 0;
  const previousPageIdx = currentRefinement - 1;
  const nextPageIdx = currentRefinement + 1;
  const lastPageIdx = nbPages - 1;

  const css = {
    root: classnames({
      'faceted-search-pagination': true,
      'faceted-search-pagination--one-page': pages && pages.length === 1,
    })
  }

  return (
    <div className={css.root}>
      <div className='pagination'>
        <PaginationButton
          icon={firstPageIcon}
          isDisabled={isFirstPage}
          onClickHandler={() => refine(firstPageIdx)}
          label={'First page'}/>
        <PaginationButton
          icon={prevPageIcon}
          isDisabled={isFirstPage}
          onClickHandler={() => refine(previousPageIdx)}
          label={'Previous page'}/>
        <PaginationDropdown
          pages={pages}
          refine={refine}
          currentRefinementLabel={currentRefinementLabel}
          currentRefinement={currentRefinement}/>
        <PaginationButton
          icon={nextPageIcon}
          isDisabled={isLastPage}
          onClickHandler={() => refine(nextPageIdx)}
          label='Next page'/>
        <PaginationButton
          icon={lastPageIcon}
          isDisabled={isLastPage}
          onClickHandler={() => refine(lastPageIdx)}
          label='Last page'/>
      </div>
    </div>
  );
};

export default Pagination;
