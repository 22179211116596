export const ItemsPerPageDefault = 48;

export const mappingTypes = {
  card: 'card',
  accordion: 'accordion',
  table: 'table',
}

export const layouts = {
  standard: 'standard',
  compact: 'compact',
  horizontal: 'horizontal',
};

export const CardDataFields = {
  imageUrl: '',
  badges: '',
  pretitle: '',
  title: '',
  subtitle: '',
  body: '',
  links: '',
}

export const AccordionDataFields = {
  imageUrl: '',
  title: '',
  badge: '',
  body: '',
  markdown: '',
  descriptionList: '',
  descriptionListLabels: '',
  links: '',
}

export const TableDataFields = {
  columns: '',
  labels: '',
};
