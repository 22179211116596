import { componentType } from './editor';
import { componentLabel } from '../utils/editor';
import displayCode from './icons/display-code.svg';

export default function loadBlock(editor, opts = {}) {
  editor.BlockManager.add(componentType, {
    label: componentLabel('Code container', displayCode),
    category: 'React Components',
    activate: true,
    select: true,
    content: {type: componentType}
  });
}
