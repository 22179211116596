import * as React from 'react';
import { useHits } from 'react-instantsearch';
import { TableBody, TableComponent, TableHeader } from '../../faceted-search/react/search-results/TableSearchResult';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function (props) {
  const {
    columns,
    index,
    sortByItems,
    tableStyle
  } = props;
  const hitsData = useHits(props);
  const {items, results, banner, sendEvent} = hitsData;
  const {fields} = index;
  const columnIDs = Object.keys(fields);
  const columnLabels = columnIDs.map(id => columns[id]);

  if (!items.length) {
    return <div className='no-results pt-3 pb-3'>{I18n.t('faceted_search.stats.no_results')}</div>
  }

  return (
    <div className='table-wrapper mb-3'>
      <TableComponent tableStyle={tableStyle}>
        <TableHeader columns={columnIDs} labels={columnLabels} sortByItems={sortByItems} sortable={true}/>
        <TableBody columns={columnIDs} rows={items}/>
      </TableComponent>
    </div>
  );
}
