import * as React from 'react';
import { Configure } from 'react-instantsearch';
import { Stats } from './Stats';
import { ClearFilters } from './ClearFilters';
import { Pills } from './Pills';
import { FieldsMappingContextProvider } from './Context';
import Pagination from './Pagination';
import CurrentPageStats from './CurrentPageStats';
import FacetedSearchHitsPerPage from './FacetedSearchHitsPerPage';
import Offcanvas from './Offcanvas';
import { getEnabled, sortByWeight } from './helper';
import { OffcanvasContent, Sidebar } from './layouts/standard/Sidebar';

export const FacetedSearchLayoutStandard = (props) => {
  const {
    filterBy,
    title,
    sortByOptions,
    filters,
    disabledFilters,
    quickFiltersRef,
    css,
    itemsPerPage,
    SearchResults,
    viewMode,
    fieldsMapping,
    showFacetsCount = false,
    stickyNavigation = false,
  } = props;

  const activeFilters = getEnabled(filters, disabledFilters);
  activeFilters.sort(sortByWeight);

  return (
    <>
      {filterBy && filterBy.length && <Configure filters={filterBy}/>}
      <Offcanvas title={'Filters'}>
        <OffcanvasContent filters={activeFilters} sortByOptions={sortByOptions}/>
      </Offcanvas>
      <div className='container faceted-filters my-3'>
        <div className='row'>
          {title && <div className='col-lg-12 col-md-12 col-sm-12'>
            <h2 className={css.heading}>{title}</h2>
          </div>}
          <div className='col-lg-3 col-md-12 col-sm-12'>
            <Sidebar
              sortByOptions={sortByOptions}
              filters={activeFilters}
              showFacetsCount={showFacetsCount}
              stickyNavigation={stickyNavigation}
            />
          </div>
          <div className='col-lg-9 col-md-12 col-sm-12'>
            <div className={css.stats}>
              <Stats/>
              <Pagination/>
            </div>

            <div className={css.pills} ref={quickFiltersRef}>
              <ClearFilters/>
              <div className='ais-CurrentRefinements-outer'>
                <Pills filters={filters} container={quickFiltersRef}/>
              </div>
            </div>

            <div className={css.root}>
              <FieldsMappingContextProvider>
                <SearchResults viewMode={viewMode} fieldsMapping={fieldsMapping}/>
              </FieldsMappingContextProvider>
            </div>

            <div className='faceted-search__footer footer'>
              <div className='footer__per-page '>
                <FacetedSearchHitsPerPage itemsPerPage={itemsPerPage}/>
              </div>
              <div className='footer__stats'>
                <CurrentPageStats/>
              </div>
              <div className='footer__pagination'>
                <Pagination/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};
