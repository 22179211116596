import * as React from 'react';
import { HitsPerPage } from 'react-instantsearch';

const buildItems = (initialValue) => {
  let itemsList = [12, 24, 36];
  if (!itemsList.includes(initialValue)) {
    itemsList = [parseInt(initialValue), ...itemsList];
  }
  itemsList.sort((a, b) => a - b);
  return itemsList.map((item, idx) => {
    return {value: item, label: `${item}`, default: idx === 0};
  });
}

const FacetedSearchHitsPerPage = (props) => {
  const {itemsPerPage} = props;

  return (
    <div className='faceted-search-per-page'>
      <div className='items-per-page'>
        <span>Display</span>
        <HitsPerPage items={buildItems(itemsPerPage)}/>
        <span>per page</span>
      </div>
    </div>
  );
};

export default FacetedSearchHitsPerPage;
