import * as React from 'react';
import { useInstantSearch } from 'react-instantsearch';

const CurrentPageStats = (props) => {
  const stateResults = useInstantSearch(props);

  if (!stateResults.results) {
    return null;
  }

  const {nbHits, hitsPerPage, page, hits} = stateResults.results;

  let maxItems = hitsPerPage * (page + 1);
  if (maxItems > nbHits) {
    maxItems = nbHits;
  }
  const minItems = maxItems - hits.length + 1;

  return <div><b>{minItems} - {maxItems}</b> of {nbHits}</div>;
};

export default CurrentPageStats;
