import {
  componentType,
  showModalCommandName
} from './editor';
import CodeContainer from '../CodeContainer';

const CODE_SAMPLE = `
// A simple JavaScript program to calculate the factorial of a number

function factorial(n) {
  // Base case: factorial of 0 is 1
  if (n === 0) {
    return 1;
  }

  // Recursive case: factorial of n is n * factorial(n-1)
  return n * factorial(n - 1);
}

// Get input from the user
const number = parseInt(prompt("Enter a non-negative integer:"));

// Calculate the factorial
const result = factorial(number);
`;

export default function loadComponent(editor, options) {
  const {supportedLanguagesList} = options;
  const linkTargetOptions = [
    {value: '_self', name: '_self'},
    {value: '_blank', name: '_blank'},
  ];

  const traits = [
    {
      label: 'Language',
      type: 'select',
      name: 'pLanguage',
      options: supportedLanguagesList,
      value: supportedLanguagesList[0].id,
    },
    {
      type: 'number',
      name: 'rows',
      label: 'Rows',
      placeholder: '10-50',
      min: 10,
      max: 50,
      step: 1,
    },
    {
      type: 'checkbox',
      name: 'showLineNumbers',
      label: 'Line numbers'
    },
    {
      type: 'checkbox',
      name: 'wrapLongLines',
      label: 'Wrap long lines'
    },
    {
      type: 'checkbox',
      name: 'show_collapse_expand_toggle',
      label: 'Show collapse/expand toggle'
    },
    {
      type: 'checkbox',
      name: 'expanded',
      label: 'Expanded view'
    },
    {
      type: 'text',
      name: 'link_1_text',
      label: 'Link 1 text'
    },
    {
      type: 'text',
      name: 'link_1_url',
      label: 'Link 1 URL'
    },
    {
      type: 'select',
      name: 'link_1_target',
      label: 'Link 1 target',
      options: linkTargetOptions,
    },
    {
      type: 'text',
      name: 'link_2_text',
      label: 'Link 2 text'
    },
    {
      type: 'text',
      name: 'link_2_url',
      label: 'Link 2 URL'
    },
    {
      type: 'select',
      name: 'link_2_target',
      label: 'Link 2 target',
      options: linkTargetOptions,
    },
  ];
  const componentDefinition = {
    extend: 'react-component',
    model: {
      toHTML(opts = {}) {
        let props = JSON.stringify(this.getAttrToHTML());

        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = this.getEl().innerHTML;

        const tempContainer2 = document.createElement('div');
        tempContainer2.innerHTML = this.getInnerHTML();
        if (!tempContainer.querySelector('textarea') || !tempContainer2.querySelector('textarea')) {
          return;
        }
        tempContainer2.querySelector('textarea').innerHTML = tempContainer.querySelector('textarea').innerHTML;

        let klass = this.get('type');
        let attributes = {
          'data-react-init-class': klass,
          'data-react-props': props,
          'data-react-cache-id': `${klass}-${this.ccid}`
        };

        const element = document.createElement('div');
        Object.entries(attributes).forEach(item => {
          const [attr_name, value] = item;
          element.setAttribute(attr_name, value);
        });
        element.innerHTML = tempContainer2.innerHTML;

        return element.outerHTML;
      },
      init() {
        const langIds = options.supportedLanguagesList.map(l => l.id);
        const val = this.get('pLanguage') || langIds[0];
        this.set('pLanguage', val);
      },
      defaults: {
        components: [
          {
            type: 'text',
            selectable: false,
            hoverable: false,
            layerable: false,
            locked: true,
            content: `<div><pre><textarea>${CODE_SAMPLE}</textarea></pre></div>`
          }
        ],
        traits,
        name: 'Code container',
        classes: ['nv-code-container'],
        component: CodeContainer,
      },
    },
    view: {
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        // Triggered when component dropped to the canvas.
        const target = this.model;
        editor.runCommand(showModalCommandName, {target})
      },
    }
  };

  editor.Components.addType(componentType, componentDefinition);
}
