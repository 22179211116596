import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import { FilterButton, widgetsComponentsMap, widgetsComponentsMapHandler } from '../../helper';
import { FacetedSearchBox } from '../../filters/FacetedSearchBox';
import SortByWidget from '../../SortByWidget';

const filterState = {
  none: undefined,
  sortBy: 'sortBy',
  filter: 'filter',
  collapsed: 'collapsed',
}

/**
 * Filters widget.
 *
 * @param {Object} filters
 * @constructor
 */
const FiltersWidget = ({filters}) => {
  if (!filters) {
    return null;
  }

  const cssClasses = classnames({
    'faceted-filters-form--widgets': true,
    'single-widget': Array.isArray(filters) && filters.length === 1,
  })

  const widgetsComponents = widgetsComponentsMap();

  return <div className={cssClasses}>
    {filters.map((filter) => widgetsComponentsMapHandler(filter, widgetsComponents))}
  </div>;
};

export const OffcanvasContent = (props) => {
  const {
    filters,
    sortByOptions,
  } = props;

  return (
    <div className='faceted-filters'>
      <div className='faceted-filters-form'>
        <SortByWidget items={sortByOptions}/>
        <FiltersWidget filters={filters}/>
      </div>
    </div>
  );
};

export const Sidebar = (props) => {
  const {sortByOptions} = props;
  const {filters, showFacetsCount, stickyNavigation} = props;
  const [currentFiltersState] = useState(filterState.none);

  const css = {
    sorting: classnames({
      'faceted-filters-form--filters_wrapper': true,
      'visible': currentFiltersState === filterState.sortBy,
    }),
    filters: classnames({
      'faceted-filters-form--filters_wrapper': true,
      'faceted-filters-list': true,
      'visible': currentFiltersState === filterState.filter,
      'faceted-filters--with-counter': showFacetsCount,
    }),
    filtersToggler: classnames({
      'faceted-filters-form--filters_toggler': true,
      'sort-by': currentFiltersState === filterState.sortBy,
      'filter': currentFiltersState === filterState.filter,
      'collapsed': currentFiltersState === filterState.collapsed,
      'inactive': ![filterState.sortBy, filterState.filter, filterState.collapsed].includes(currentFiltersState)
    }),
    navigationWrappers: classnames({
      'faceted-filters-form--filters_wrappers': true,
      'sticky-top': stickyNavigation
    }),
  };

  return (
    <div className="faceted-filters-form">
      <div className={css.filtersToggler}>
        <div className={'text-search-panel text-search-panel--mobile'}>
          <FacetedSearchBox/>
        </div>
        <FilterButton/>
      </div>

      <div className={css.navigationWrappers}>
        <div className='faceted-filters-form__header'>
          <p className="h--smaller faceted-filters-form__title">
            {I18n.t('faceted_search.filters.label')}
          </p>
        </div>

        <div className={'text-search-panel text-search-panel--desktop'}>
          <FacetedSearchBox/>
        </div>

        <div className={css.sorting}>
          <SortByWidget items={sortByOptions}/>
        </div>

        <div className={css.filters}>
          <FiltersWidget filters={filters}/>
        </div>
      </div>
    </div>
  );
}
