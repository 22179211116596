import * as React from 'react';
import { useStats } from 'react-instantsearch';

export const Stats = (props) => {
  const { nbHits } = useStats(props);
  const messageKey = nbHits > 0 ? 'faceted_search.stats.results_found' : 'faceted_search.stats.no_results';

  return (
    <div className="faceted-search-stats">
      <p className="h--smaller mb-0">{I18n.t(messageKey, { count: nbHits })}</p>
    </div>
  );
};
