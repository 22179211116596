import { combineReducers, configureStore, createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    columns: {},
    indexes: [],
    filters: {},
    searchClientConfig: {},
  },
  reducers: {
    setColumns(state, {payload}) {
      state.columns = payload;
    },
    setIndexes(state, {payload}) {
      state.indexes = payload;
    },
    addFilters(state, {payload}) {
      state.filters = {...state.filters, ...payload};
    },
    setSearchClientConfig(state, {payload}) {
      state.searchClientConfig = payload;
    },
  }
})

export const {
  setColumns,
  setIndexes,
  addFilters,
  setSearchClientConfig,
} = appSlice.actions;

const reducer = combineReducers({
  app: appSlice.reducer,
})

const store = configureStore({reducer});

export default store;
