import * as React from 'react';
import { SortBy } from 'react-instantsearch';
import classnames from "classnames";

/**
 * Sort by widget.
 *
 * @param {Object} props
 * @param {Array} props.items
 * @returns {JSX.Element}
 * @constructor
 */
export default function (props) {
  const {items} = props;
  const cssClasses = classnames({
    'faceted-filters-form__sorting-widget': true,
    'd-none': items && Array.isArray(items) && items.length === 1,
  });

  return (
    <div className={cssClasses}>
      <div className="faceted-filters-form__sorting-header--no-border">
        {I18n.t('faceted_search.filters.sort_by')}
      </div>
      <div className="p-0">
        <SortBy items={items}/>
      </div>
    </div>
  );
};
