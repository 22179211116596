import * as React from "react";
import {useCallback, useState, useEffect} from "react";
import { Survey } from 'survey-react-ui';
import { StylesManager, Model, ComponentCollection } from 'survey-core';
import { Code } from 'react-content-loader';
import { UserProfile } from './survey-questions/user-profile';
import { MicroOptIn } from './survey-questions/micro-opt-in';
import { Email } from './survey-questions/email';
import { ChinaPIPLTerms } from './survey-questions/china-pipl-terms';
import showdown from 'showdown';
import registerCustomElements from './webform/elements';
import { ProgramApplicationStatusDialog } from './webform/elements/ProgramApplicationStatusDialog';
import { addProgramApplicationElements, addProgramApplicationFormProgressBar } from './webform/program-application';
import ExpiredTokenDialog from './webform/elements/ExpiredTokenDialog';
import {addError} from '../packs/tracing';

ComponentCollection.Instance.add(UserProfile);
ComponentCollection.Instance.add(MicroOptIn);
ComponentCollection.Instance.add(Email);
ComponentCollection.Instance.add(ChinaPIPLTerms);

registerCustomElements();

let css = {
  root: "nv-form",
  navigationButton: 'btn-cta',
  checkbox: {
    item: "nv-checkbox-item",
    materialDecorator: "",
    itemChecked: "nv-checkbox-item--checked",
    itemOnError: "nv-checkbox-item--error"
  },
  question: {
    title: "nv-q__title",
    hasError: "nv-question__error",
  },
  text : {
    root: "sd-input sd-text form-control",
  },
  comment: {
    root: "sd-input sd-comment form-control",
  },
  radiogroup: {
    controlLabel: "nv-item__control-label",
    itemChecked: "nv-radio-item--checked",
    item: "nv-radio--item",
    itemOnError: "nv-radio--item--error"
  },
  matrix: {
    tableWrapper: "nv-matrix",
    root: "sd-table sd-matrix__table table",
    label: "nv-item__control-label",
    itemChecked: "nv-radio-item--checked",
    itemOnError: "nv-radio--item--error",
  },
  error: {
    root: "nv-question__erbox",
    locationBottom: "nv-question__erbox--location--bottom",
    locationTop: "nv-question__erbox--location--top",
  },
  panel: {
    container: "sv_p_container nv-row__panel",
  },
};

function showChinaPIPLTerms() {
  return location.host.endsWith('.cn') || location.host.endsWith('cn:3000');
}

function addChinaPIPLTerms(data) {
  data['pages'][0]['elements'].push({
    type: 'china-pipl-terms',
    name: 'china-pipl-terms',
    isRequired: true,
    titleLocation: 'hidden'
  });

  return data;
}

async function checkTokenExpiration() {
  const response = await fetch('/auth/token.json');

  return response.ok ? await response.json() : { expired: true };
}

async function checkIfUserSignedIn() {
  const response = await fetch('/api/user.json');
  const data = await response.json();

  return [response.ok, data];
}

async function updateUserProfile(data) {
  let body = { profile: data };
  let options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken()
    },
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch('/user/profile.json', options);
    if (response.ok) {
      return response.json();
    } else {
      // TODO: prompt the user to reauthenticate
    }
  } catch (e) {
    // TODO: prompt the user to reauthenticate
    console.error('Error:', error);
  }
}

function getUserProfileQuestion(model) {
  let question = false;
  model.getAllQuestions().forEach(q => {
    if ('user-profile' === q.getType()) {
      question = q.getPlainData();
    }
  });

  return question;
}

function Webform(props) {
  const [loading, setLoading] = useState(true);
  const [survey, setSurvey] = useState(null);
  const [applicationStatus, setApplicationStatus] = useState(false);
  const [applicationStatusDescription, setApplicationStatusDescription] = useState(false);
  const [memberPageURL, setMemberPageURL] = useState(false);
  const [infoPageURL, setInfoPageURL] = useState(false);
  const [submitPath, setSubmitPath] = useState(`/forms/${props.formId}/submissions`);
  const [updatePath, setUpdatePath] = useState('');
  const [programId, setProgramId] = useState(null);
  const [hasUserProfileQuestion, setHasUserProfileQuestion] = useState(false);
  const [userState, setUserState] = useState({
    signedIn: false,
    idTokenExpired: false,
    email: null,
  });

  let isNewUser = false;
  const newUserTag = document.querySelector('meta[name="new-user"]');
  if (newUserTag) {
    isNewUser = newUserTag.getAttribute('content') === 'true';
  }

  if (props.mode === 'display') {
    css = {};
    StylesManager.applyTheme('survey');
  } else {
    StylesManager.applyTheme('bootstrap');
  }

  function renderAlert(data = {}) {
    if (typeof data.message === 'undefined' || typeof data.message === 'undefined') {
      data.message = I18n.t('alerts.error');
      data.type = 'warning';
    }

    let headerContainer = document.getElementById('header');
    let modalHeaderContainer = document.querySelector('#nv-modal .nv-modal-header');
    const isModalOpen = !!document.querySelector('.nv-modal:not(.nv-modal--closed)');

    if (isModalOpen && modalHeaderContainer) {
      const {type: alertType} = data;
      headerContainer = modalHeaderContainer;

      let status = data.type === 'success' ? 'success' : 'error';
      let modalTitle = I18n.t(`alerts.modal.header.${status}`);

      if (modalTitle) {
        let modalTitleContainer = document.querySelector('#nv-modal .nv-modal-title');
        modalTitleContainer.innerHTML = modalTitle;
      }

      let descriptionContainer = document.querySelector('#nv-modal .form-description');
      descriptionContainer.innerHTML = data.message;
    } else {
      let alertsContainerId = 'js-webform-alerts-container';
      let messagesContainer = document.getElementById(alertsContainerId);

      if (messagesContainer) {
        messagesContainer.parentNode.removeChild(messagesContainer);
      }

      messagesContainer = document.createElement('div')
      messagesContainer.id = alertsContainerId;
      headerContainer.after(messagesContainer);

      let hasOldAlerts = false;

      const alerts = document.querySelectorAll('.js-webform-alerts');

      if (alerts.length) {
        hasOldAlerts = true;
        alerts.forEach((alert) => {
          $(alert).remove();
        });
      }

      let alert = document.createElement('div');
      alert.className = 'js-webform-alerts'
      alert.innerHTML = `<div class='alert alert-${data.type} border-start-0 border-end-0 rounded-0' role='alert'><div class='container'>${data.message}</div></div>`;
      messagesContainer.append(alert);

      if (hasOldAlerts) {
        $(alert).fadeOut(() => $(alert).fadeIn());
      }

      messagesContainer.scrollIntoView();
    }
  }

  function handleRequestError(error) {
    renderAlert();
    addError('webform', error);
  }

  const updateProfile = useCallback((sender, userSignedIn, profileQuestion, handler) => {
    if (userSignedIn && profileQuestion && profileQuestion.name) {
      const profileData = sender.data[profileQuestion.name];
      updateUserProfile(profileData)
        .then(handler);
      return;
    }

    handler();
  }, [submitPath, updatePath, userState]);

  const saveSubmission = useCallback((sender, redirect) => {
    let csrfToken = getCsrfToken();

    let params = {
      data: sender.data
    };

    let method = props.mode === 'edit' ? 'PUT' : 'POST';

    fetch(submitPath, {
      method: method,
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    }).then((data) => {
      if (typeof data.redirect_to !== 'undefined') {
        window.location = data.redirect_to;
        return;
      }

      renderAlert(data);

      if (redirect) {
        setTimeout(() => window.location = redirect, 1500);
      }
    }).catch((error) => {
      if (typeof error === 'object' && 'status' in error && error.status == 422) {
        error.json().then((data) => {
          renderAlert(data);
        });
      } else {
        handleRequestError(error);
      }
    });
  }, [submitPath]);

  const updateSubmission = useCallback((sender) => {
    if (!updatePath) {
      return;
    }

    const csrfToken = getCsrfToken();
    const params = {
      data: sender.data
    };

    fetch(updatePath, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(response => {
        renderAlert(response);
      })
      .catch(error => handleRequestError(error));

  }, [updatePath]);

  const reloadForm = useCallback((sender) => {
    const {data} = sender;
    sender.clear();
    sender.data = data;
  }, [updatePath]);

  function getForm(formId, version) {
    formId = parseInt(formId);
    version = parseInt(version);

    if (formId <= 0) {
      setLoading(true);
      return;
    }

    let formPath = `/forms/${formId}`
    let params = `?version=${version}`;

    if (!isNaN(version)) {
      formPath += params;
    }

    checkIfUserSignedIn()
      .then(([isSignedIn, data]) => {
        setUserState(prevState => ({
          ...prevState,
          signedIn: isSignedIn,
          email: isSignedIn ? data.email : null,
        }));
      })
      .then(checkTokenExpiration)
      .then(({expired}) => {
        setUserState(prevState => ({...prevState, idTokenExpired: expired}))
      })
      .then(() => fetch(formPath))
      .then(response => response.json())
      .then(data => {
        let redirect = null;
        if (data.navigateToUrl) {
          redirect = data.navigateToUrl
          delete data.navigateToUrl;
        }

        if (showChinaPIPLTerms()) {
          data = addChinaPIPLTerms(data);
        }

        let {isProgramApplicationForm, showEmailTooltip = false} = data;
        if (location.pathname.startsWith('/nv/admin/forms') ||
            location.pathname.startsWith('/nv/admin/programs')) {
          isProgramApplicationForm = false;
        }

        if (isProgramApplicationForm) {
          css.root = `${css.root} pa-form ${isNewUser ? 'pa-form--new-user' : ''}`;
          addProgramApplicationElements(data, showEmailTooltip, isNewUser);
        }

        if (props.hide_title) {
          const {title, description} = data;
          const titleElement = document.querySelector('#nv-modal .nv-modal-title');

          const localizedValue = (item) => {
            const locale = I18n.locale.toLowerCase();
            if (!item) {
              return '';
            }
            if (typeof item === 'object') {
              return item[locale] || item?.default || '';
            }
            return item;
          }

          if (title && titleElement) {
            titleElement.innerHTML = localizedValue(title);
          }

          const descriptionElement = document.querySelector('#nv-modal .form-description');
          if (description && descriptionElement) {
            descriptionElement.innerHTML = localizedValue(description);
          }

          data.title = '';
        }

        let model = new Model(data);
        model.showCompletedPage = false;
        model.focusFirstQuestionAutomatic = false;

        if (isProgramApplicationForm) {
          addProgramApplicationFormProgressBar(model);
        }

        const userProfileQuestion = getUserProfileQuestion(model);
        setHasUserProfileQuestion(!!userProfileQuestion);

        model.onAfterRenderQuestion.add(function(sender, options) {
          let title = options.htmlElement.getElementsByTagName('H5')[0];
          if (title) {
            title.outerHTML = `
              <label for="${options.question.inputId}"
                     id="${options.htmlElement.id}_ariaTitle"
                     class="${title.className}">
                ${title.innerHTML}
              </label>
            `;
          }
        });

        let converter = new showdown.Converter();
        model.onTextMarkdown.add(function(survey, options){
          //convert the markdown text to html
          let str = converter.makeHtml(options.text);
          //remove root paragraphs <p></p>
          str = str.substring(3);
          str = str.substring(0, str.length - 4);
          //set html
          options.html = str;
        });

        if (data.editable && data.submission && data.submission.data) {
          const { id: submissionId, form_id: formId, data: submissionData } = data.submission;
          if (!updatePath) {
            setUpdatePath(`/forms/${formId}/submissions/${submissionId}.json`);
          }
          model.data = submissionData;
          model.onComplete.add((sender) => {
            updateProfile(sender, userState.signedIn, userProfileQuestion, () => {
              updateSubmission(sender);
            });
          });
          model.onComplete.add(reloadForm);
        } else {
          model.onComplete.add((sender) => {
            updateProfile(sender, userState.signedIn, userProfileQuestion, () => {
              saveSubmission(sender, redirect);
            });
          });
        }

        if (typeof window.I18n !== 'undefined') {
          model.locale = window.I18n.locale.toLowerCase();
        }

        if (props.mode === 'display') {
          model.mode = 'display';
        }

        if (typeof props.data !== 'undefined') {
          model.data = props.data;
        }

        setSurvey(model);

        if (typeof data.program_id !== 'undefined') {
          setProgramId(data.program_id);

          if (props.mode === 'display' || props.mode === 'edit') {
            initializeForm(data.program_id);
          } else {
            fetch(`/programs/${data.program_id}/applications.json`)
              .then(response => {
                if (response.ok) {
                  return response.json();
                }

                throw new Error(`HTTP error ${response.status}`);
              })
              .then(data => {
                setApplicationStatus(data.status);
                setApplicationStatusDescription(data.description);
                setMemberPageURL(data.member_page_url);
                setInfoPageURL(data.info_page_url);
              })
              .catch(e => initializeForm(data.program_id));
            }
        } else {
          setLoading(false);
          setApplicationStatus(false);
        }
      })
      .catch((e) => {
        addError('webform', e);
      });
  }

  function initializeForm(programId) {
    let path = `/programs/${programId}/applications.json`;
    setSubmitPath(path);
    setLoading(false);
  }

  useEffect(() => {
    getForm(props.formId, props.version);
  }, [props.formId, submitPath, updatePath, userState.signedIn, userState.idTokenExpired]);

  if (applicationStatus) {
    return <ProgramApplicationStatusDialog
      status={applicationStatus}
      description={applicationStatusDescription}
      programId={programId}
      memberPageURL={memberPageURL}
      infoPageURL={infoPageURL} />
  } else if (loading) {
    return(
      <div className='py-4'>
        <Code speed={2} width={340} height={84} viewBox="0 0 340 84" />
      </div>
    );
  }

  if (!location.pathname.startsWith('/nv/admin/programs') &&
      userState.idTokenExpired && userState.signedIn && hasUserProfileQuestion) {
    return <ExpiredTokenDialog email={userState.email} />
  }

  return <Survey model={survey} css={css} />;
}

export default Webform;
