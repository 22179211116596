import * as React from 'react';
import { useMenu } from 'react-instantsearch-core';

const Panel = (props) => {
  const {header} = props;
  return (
    <div className="ais-Panel fs-widget fs-widget--menu-select">
      <div className="ais-Panel-header">
        <div className="ais-Panel-header-line"></div>
        {header}
      </div>
      <div className="ais-Panel-body">
        {props.children}
      </div>
    </div>
  );
}

export const FacetedSearchMenuSelect = (props) => {
  const {label} = props;
  const { items, refine } = useMenu(props);
  const { value: selectedValue } = items.find((item) => item.isRefined) || {
    value: '',
  };

  const onChangeHandler = (event) => {
    refine(event.target.value);
  };

  return (
    <Panel header={label} className={'fs-widget fs-widget--menu-select'}>
      <select value={selectedValue} onChange={onChangeHandler}>
        {items.filter(i => i.value !== '' && i.label !== '').map((item) => (
          <option value={item.value} key={`${item.value}:${item.count}`}>
            {item.label.trim()} ({item.count})
          </option>
        ))}
      </select>
    </Panel>
  )
}

function MenuSelect(props) {
  const {items, refine} = useMenu(props, {
    $$widgetType: 'custom.menuSelect',
  });

  return (
    <select onChange={(event) => refine(event.target.value)}>
      {items.map((item) => (
        <option key={item.label} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}
