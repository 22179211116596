import * as React from 'react';
import { FieldsMappingContextProvider } from './Context';
import { Configure } from 'react-instantsearch';
import { FiltersBlock } from './layouts/horizontal/FiltersBlock';
import { ClearFilters } from './ClearFilters';
import { Stats } from './Stats';
import { Pills } from './Pills';
import Pagination from './Pagination';
import CurrentPageStats from './CurrentPageStats';
import FacetedSearchHitsPerPage from './FacetedSearchHitsPerPage';
import classnames from 'classnames';
import Offcanvas from './Offcanvas';
import { getEnabled, sortByWeight, widgetsComponentsMap, widgetsComponentsMapHandler } from './helper';
import SortByWidget from './SortByWidget';

const OffcanvasContent = (props) => {
  const {
    filters
  } = props;
  const widgetsComponents = widgetsComponentsMap();

  return (
    <div className='faceted-filters-form'>
      {filters.map((filter) => widgetsComponentsMapHandler(filter, widgetsComponents))}
    </div>
  );
};

export const FacetedSearchLayoutHorizontal = (props) => {
  const {
    css,
    SearchResults,
    filters,
    fieldsMapping,
    quickFiltersRef,
    viewMode,
    filterBy,
    disabledFilters,
    sortByOptions,
    itemsPerPage,
    showFacetsCount = false,
    stickyNavigation = false,
  } = props;

  const enabledFilters = getEnabled(filters, disabledFilters);

  if (enabledFilters) {
    enabledFilters.sort(sortByWeight);
  }

  const localCss = {
    root: classnames({
      'container': true,
      'my-3': true,
      'faceted-filters': true,
      'faceted-filters--horizontal': true,
      'faceted-filters--with-counter': showFacetsCount,
    }),
    navigationRow: classnames({
      'row': true,
      'navigation-row': true,
      'sticky-top': stickyNavigation,
    }),
  };

  return (
    <div className={localCss.root}>
      <div className={localCss.navigationRow}>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          {filterBy && filterBy.length && <Configure filters={filterBy}/>}
          <Offcanvas title={'Filters'}>
            <OffcanvasContent filters={enabledFilters}/>
          </Offcanvas>
          <div className='filters-block-wrapper'>
            <p className='filters__label p--medium fw-bold mb-0 d-flex align-items-center'>
              {I18n.t('faceted_search.filters.label_horizontal')}
            </p>
            <FiltersBlock filters={enabledFilters}/>
            <ClearFilters/>
            <div className={css.pills}>
              <Pills filters={filters} container={quickFiltersRef}/>
            </div>
          </div>
        </div>
      </div>
      <div className='row align-items-center stats-row'>
        <div className='col-12 col-sm-6 mb-3 mb-sm-0'>
          <Stats/>
        </div>
        <div className='col-12 col-sm-6 text-sm-end'>
          <SortByWidget items={sortByOptions}/>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className={css.root}>
            <FieldsMappingContextProvider>
              <SearchResults viewMode={viewMode} fieldsMapping={fieldsMapping}/>
            </FieldsMappingContextProvider>
          </div>
        </div>
      </div>
      <div className='row align-items-center footer-row'>
        <div className='col-lg-4'>
          <FacetedSearchHitsPerPage itemsPerPage={itemsPerPage}/>
        </div>
        <div className='col-lg-4 text-center'>
          <CurrentPageStats/>
        </div>
        <div className='col-lg-4'>
          <Pagination/>
        </div>
      </div>
    </div>
  )
};
