import { componentLabel, customComponentsCategory } from '../utils/editor';
import loadComponent from './component';
import tableListIcon from './icons/table-list.svg';

function loadBlock(editor, options = {}) {
  editor.BlockManager.add(options.componentType, {
    label: componentLabel(options.componentName, tableListIcon),
    category: options.category,
    content: '<GroupedTable />'
  });
}

export default function (editor, opts = {}) {
  const defaultOptions = {
    componentType: 'GroupedTable',
    componentName: 'Grouped table',
    category: customComponentsCategory,
  };

  const options = {
    ...defaultOptions,
    ...opts
  };

  loadComponent(editor, options);
  loadBlock(editor, options);
}
