import GroupedTable from "../GroupedTable";

function componentDefinition(editor, options) {
  const {componentType, componentName} = options;

  return {
    extend: 'react-component',
    model: {
      defaults: {
        'custom-name': componentName,
        component: GroupedTable,
        traits: [
          {
            type: 'text',
            name: 'config_url',
            label: 'Config URL',
            required: true,
          },
          {
            type: 'select',
            name: 'table_style',
            label: 'Style',
            options: [
              {id: '', name: 'Default'},
              {id: 'nv-table-bg-1', name: 'Background 1'},
              {id: 'nv-table-bg-2', name: 'Background 2'},
              {id: 'nv-table-alt-col-1', name: 'Alternating Columns 1'},
              {id: 'nv-table-alt-col-2', name: 'Alternating Columns 2'},
              {id: 'nv-table-alt-row-1', name: 'Alternating Rows 1'},
              {id: 'nv-table-alt-row-2', name: 'Alternating Rows 2'},
            ],
          },
        ],
      },
    },
    isComponent: el => el.tagName === componentType.toUpperCase()
  }
}

export default function (editor, options = {}) {
  editor.Components.addType(options.componentType, componentDefinition(editor, options));
}
