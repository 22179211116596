import * as React from 'react';
import { useRef } from 'react';
import { RefinementList } from 'react-instantsearch';
import { ChevronSmall, toggleFilterState } from '../helper';

// https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/#widget-param-limit
const REFINEMENT_LIST_LIMIT = 20;
const REFINEMENT_LIST_LIMIT_MAX = 200;

/**
 * Panel with checkboxes.
 *
 * @param {Object} props
 * @param {string} props.attributeName
 * @param {string} props.header
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const FacetedSearchRefinementList = (props) => {
  const panelElement = useRef();
  const limit = props['showAll'] ? REFINEMENT_LIST_LIMIT_MAX : REFINEMENT_LIST_LIMIT;
  const {attribute, header} = props;

  return (
    <div className="fs-widget fs-widget--checkboxes">
      <div className="fs-widget__panel" ref={panelElement}>
        <div className="ais-Panel">
          <div className="ais-Panel-header" onClick={() => toggleFilterState(panelElement.current)}>
            <div className="ais-Panel-header-line"></div>
            {header}
            <div className="fs-widget__toggler">
              <ChevronSmall/>
            </div>
          </div>
          <div className="ais-Panel-body">
            <RefinementList
              attribute={attribute}
              searchable={false}
              limit={limit}
              sortBy={['isRefined', 'name:asc']}
              transformItems={(items) => {
                if (panelElement.current) {
                  if (!items.length) {
                    panelElement.current.classList.add('panel--empty');
                  } else {
                    panelElement.current.classList.remove('panel--empty');
                  }
                }

                return items.filter(item => item.label && item.value);
              }}
              translations={{}}
              searchablePlaceholder={''}/>
          </div>
        </div>
      </div>
    </div>
  );
}
