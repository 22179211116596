import * as React from 'react';
import { useSelector } from 'react-redux';
import { DropdownFilter } from '../../faceted-search/react/DropdownFilter';
import { FacetedSearchBox } from '../../faceted-search/react/filters/FacetedSearchBox';
import { ClearFilters } from '../../faceted-search/react/ClearFilters';
import { Pills } from '../../faceted-search/react/Pills';
import {
  FilterButton,
  widgetsComponentsMap,
  widgetsComponentsMapHandler
} from '../../faceted-search/react/helper';
import Offcanvas from '../../faceted-search/react/Offcanvas';

const OffcanvasContent = (props) => {
  const {
    filters
  } = props;

  if (!filters) {
    return null;
  }

  const widgetsComponents = widgetsComponentsMap();

  return (
    <div className='faceted-filters-form'>
      {Object.values(filters).map((filter) => {
        return widgetsComponentsMapHandler(filter, widgetsComponents)
      })}
    </div>
  );
};

/**
 * Grouped table filters block.
 *
 * @param {Object} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function (props) {
  const {containerRef} = props;

  const filters = useSelector(state => state.app.filters);
  const normalizedFilters = {};
  if (filters && Object.keys(filters).length) {
    Object.values(filters).forEach(filter => {
      normalizedFilters[filter.attribute] = filter;
    });
  }

  return (
    <div className='faceted-filters'>
      <Offcanvas title={I18n.t('faceted_search.filters.label')}>
        <OffcanvasContent filters={normalizedFilters}/>
      </Offcanvas>
      <div className='filters-block-wrapper'>
        <p className='filters__label p--medium fw-bold mb-0 d-flex align-items-center'>
          {I18n.t('faceted_search.filters.label_horizontal')}
        </p>
        <div className='d-flex justify-content-between mb-0 filters-block'>
          <div className='filters d-flex align-items-center'>
            {filters && Object.keys(filters).length > 0 && Object.values(filters).map((filter) => {
              const key = `filter:${filter.attribute}`;
              return <DropdownFilter
                key={key}
                filter={filter}
                label={filter.label || filter.fieldName}
              />;
            })}
          </div>
          <div className='text-search-panel mb-0'>
            <div className='faceted-filters-form'>
              <FacetedSearchBox/>
              <FilterButton/>
            </div>
          </div>
        </div>
        <ClearFilters/>
        <div className='faceted-search-pills'>
          <Pills filters={normalizedFilters} container={containerRef}/>
        </div>
      </div>
    </div>
  );
}
