import * as React from 'react';
import { SearchBox } from 'react-instantsearch';

export const FacetedSearchBox = () => {
  return (
    <div className="fs-widget fs-widget--text">
      <SearchBox
        autoFocus={false}
        translations={{
          submitButtonTitle: I18n.t('faceted_search.filters.search_box.submit_title')
        }}
        placeholder={I18n.t('faceted_search.filters.search_box.placeholder')} />
    </div>
  );
};
