import loadBlock from './block';
import loadComponent from './component';
import loadCommands from './commands';

export const componentType = 'CodeContainer';
export const componentTypeHeader = 'CodeContainerHeader';
export const componentTypeContent = 'CodeContainerContent';
export const showModalCommandName = 'CodeContainer:modal:show';

export default function initCodeContainer(editor, opts = {}) {
  const defaults = {
    toolbarBtnCustomCode: {},
    placeholderContent: '<span>Insert code</span>',
    modalTitle: 'Insert code',
    modalButtonLabel: 'Save',
    supportedLanguagesList: [
      {id: 'plain_text', name: 'Plain Text'},
      {id: 'bash', name: 'Bash'},
      {id: 'c', name: 'C'},
      {id: 'cpp', name: 'C++'},
      {id: 'csharp', name: 'C#'},
      {id: 'python', name: 'Python'},
      {id: 'golang', name: 'Go'},
      {id: 'javascript', name: 'JavaScript'},
    ],
  };
  const options = {...defaults, ...opts};

  loadCommands(editor, options);
  loadComponent(editor, options);
  loadBlock(editor, options);
}
