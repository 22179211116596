import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

const connectionData = () => {
  const getMetaContent = (name) => document.querySelector(`meta[name="${name}"]`)?.getAttribute('content') || '';

  return [getMetaContent('typesense-key'), getMetaContent('typesense-host')];
}

const config = (key, host) => {
  return {
    server: {
      apiKey: key,
      nodes: [
        {
          host: host,
          port: 443,
          path: '',
          protocol: 'https',
        },
      ],
      cacheSearchResultsForSeconds: 120,
    },
    collectionSpecificSearchParameters: {},
  };
}

const addExtraParams = (cfg, idx, searchParams) => {
  const defaultParams = {
    'query_by': '*',
  };
  cfg.collectionSpecificSearchParameters[idx] = {...defaultParams, ...searchParams};
}

export function buildAdapter(indexIDs, searchParams) {
  if (!indexIDs.length) {
    return null;
  }

  const [key, host] = connectionData();
  const cfg = config(key, host);

  indexIDs.forEach(idx => addExtraParams(cfg, idx, searchParams));

  return new TypesenseInstantSearchAdapter(cfg);
}

export function requestErrorHandler(error) {
  console.log(error);
}
