import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHits, useSortBy } from 'react-instantsearch';
import { FieldsMappingContext } from '../Context';
import sortIcon from '../icons/sort.svg';
import checkIcon from '../icons/check.svg';
import xmarkIcon from '../icons/xmark.svg';

/**
 * Table component.
 *
 * @param {Object} props
 * @param {String} props.tableStyle
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const TableComponent = (props) => {
  const {tableStyle, children} = props;
  const tableElRef = useRef();
  let classes = ['nv-table'];
  if (tableStyle) {
    classes.push(`${tableStyle}`);
  }

  classes = classes.filter(c => c && c.length > 0);

  useEffect(() => {
    if (tableElRef.current && tableElRef.current.scrollWidth > tableElRef.current.clientWidth) {
      tableElRef.current.classList.add('with-scroll')
    }
  }, []);

  return (
    <div className={classes.join(' ')} ref={tableElRef}>
      <table>
        {children}
      </table>
    </div>
  );
}

/**
 * Header cell.
 *
 * @param {Object} props
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TableHeaderCell = (props) => {
  const {labels, idx, columnId} = props;
  return (
    <div>{labels[idx] ?? columnId}</div>
  );
}

/**
 * Sortable header cell.
 *
 * @param {Object} props
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TableHeaderSortableCell = (props) => {
  const {labels, idx, columnId, onClickHandler} = props;
  return (
    <button type='button' onClick={onClickHandler} data-attribute={columnId}>
      {labels[idx] ?? columnId} <img src={sortIcon} alt={I18n.t('faceted_search.filters.sort')}/>
    </button>
  );
}

/**
 * Table head component.
 *
 * @param {Object} props
 * @param {Array} props.columns
 * @param {Array} props.labels
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const TableHeader = (props) => {
  const {
    columns,
    labels,
    sortable = false,
    sortByItems = [],
  } = props;
  const {refine} = useSortBy({...props, items: sortByItems});
  const values = Array.from(sortByItems).map(option => option.value);
  const [currentSort, setCurrentSort] = useState('');

  const isSortable = (value) => {
    const allowed = values.filter(v => v.includes(`sort/${value}`));
    return sortable && allowed.length;
  }

  const onClickHandler = (e) => {
    const {target} = e;
    const attr = target.getAttribute('data-attribute');
    const strToSearch = `sort/${attr}`;
    const allowed = values.filter(v => v.includes(strToSearch));
    if (allowed.length) {
      const sortOption = currentSort === allowed[0] ? allowed[1] : allowed[0];
      setCurrentSort(sortOption);
      refine(sortOption);
    }
  };

  return (
    <thead className='table-head'>
    <tr>
      {columns.map((columnId, idx) => {
        return (
          <th key={columnId}>
            {isSortable(columnId) ?
              <TableHeaderSortableCell labels={labels} idx={idx} columnId={columnId} onClickHandler={onClickHandler}/> :
              <TableHeaderCell labels={labels} idx={idx} columnId={columnId}/>}
          </th>
        );
      })}
    </tr>
    </thead>
  );
}

/**
 * Table cell component.
 *
 * @param {Object} props
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const CellContent = (props) => {
  const {value} = props;
  if (typeof value === 'boolean') {
    return (
      <div className="cell__boolean">
        <img src={value ? checkIcon : xmarkIcon} alt=""/>
      </div>
    )
  }

  if (!value) {
    return <div>N/A</div>;
  }

  if (Array.isArray(value)) {
    return <div>{value.map((item, idx) => {
      return (
        <div key={idx}>{item}</div>
      );
    })}</div>
  }

  return <div>{value}</div>;
}

/**
 * Table body component.
 *
 * @param {Object} props
 * @param {Array} props.columns
 * @param {Array} props.row
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const TableBody = (props) => {
  const {columns, rows} = props;

  if (!rows.length) {
    return null;
  }

  return (
    <tbody className='table-body'>
    {rows.length && rows.map((row, rowIdx) => {
      return (
        <tr key={`row:${rowIdx}`}>
          {columns.map((columnId, columnIdx) => {
            const key = `${columnId}:${columnIdx}`;
            return (
              <td key={key}>
                <CellContent value={row[columnId] ?? null}/>
              </td>
            );
          })}
        </tr>
      );
    })}
    </tbody>
  );
}

/**
 * Table search results component.
 *
 * @param {Object} props
 *
 * @returns {JSX.Element}
 */
export default function (props) {
  const {table} = useContext(FieldsMappingContext);
  const [mapping] = table;
  const {items, results, banner, sendEvent} = useHits(props);

  const columns = mapping?.columns ? mapping.columns.split('\n') : [];
  const column_labels = mapping?.labels ? mapping.labels.split('\n') : [];

  return (
    <TableComponent>
      <TableHeader columns={columns} labels={column_labels}/>
      <TableBody columns={columns} rows={items}/>
    </TableComponent>
  );
}
