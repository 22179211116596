import * as React from 'react';
import { Code, List } from 'react-content-loader';

const PreviewCell = () => {
  const width = 240;
  const height = 30;
  return <Code speed={2} width={width} height={height} viewBox={`0 0 ${width} ${height}`}/>;
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function () {
  const columns = Array.from({length: 4}, (_, i) => i);
  const rows = Array.from({length: 10}, (_, i) => i);
  return (
    <div className="nv-table nv-table-alt-row-1">
      <table>
        <caption className="table-caption"></caption>
        <thead className="table-head">
        <tr>
          {columns.map(i => {
            return (
              <th key={i}>
                <PreviewCell/>
              </th>
            );
          })}
        </tr>
        </thead>
        <tbody className="table-body">
        {rows.map(i => {
          return <tr key={i}>
            {columns.map(i => {
              return <td key={i}>
                <PreviewCell/>
              </td>
            })}
          </tr>
        })}
        </tbody>
      </table>
    </div>
  );
}
